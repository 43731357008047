import { GenderEnum } from './utils/enums';

export const GenderOptions = [
  { label: 'Male', value: GenderEnum.Male },
  { label: 'Female', value: GenderEnum.Female },
  { label: 'Unknown', value: GenderEnum.Unknown },
];
export const EventTypeOptions = ['Physical event', 'Virtual event'];
export const TicketTypeOptions = ['Paid', 'Free'];
export const CommunityTypeOptions = ['Public', 'Private'];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const onboardingSteps = [
  {
    name: 'Contact info',
    path: '/signup/contact-information',
    step: 1,
  },
  {
    name: 'Bio data',
    path: '/signup/bio-data',
    step: 2,
  },
  {
    name: 'Username',
    path: '/signup/username',
    step: 3,
  },
  {
    name: 'Interests',
    path: '/signup/interests',
    step: 4,
  },
];

export const PRIVATE_NAVBAR_LIST = [
  {
    id: 'blog',
    name: 'Blog',
    path: 'https://blog.fusion.ng',
    external: true,
  },
  {
    id: 'faqs',
    name: 'FAQs',
    path: '#',
    external: false,
  },
  {
    id: 'support',
    name: 'Support',
    path: '#',
    external: false,
  },
];

export const USER_NAVBAR_LIST = [
  {
    id: 'view-profile',
    name: 'View Profile',
    path: '/profile',
    external: false,
  },
  {
    id: 'referrals',
    name: 'Referrals',
    path: '/referrals',
    external: false,
  },
  {
    id: 'analytics',
    name: 'Analytics',
    path: '/analytics',
  },
  {
    id: 'settings',
    name: 'Settings',
    path: '/settings',
    external: false,
  },
];

export const passwordValidations = [
  {
    label: 'Minimum of 8 characters',
    test: (pw: string) => pw.length >= 8,
  },
  {
    label: 'At least 1 uppercase letter',
    test: (pw: string) => /[A-Z]/.test(pw),
  },
  {
    label: 'At least 1 lowercase letter',
    test: (pw: string) => /[a-z]/.test(pw),
  },
  {
    label: 'At least 1 number',
    test: (pw: string) => /\d/.test(pw),
  },
  {
    label: 'At least 1 special character',
    test: (pw: string) => /[^A-Za-z0-9]/.test(pw),
  },
];

export const PUBLIC_NAVBAR_LIST = [
  {
    id: 'blog',
    name: 'Blog',
    path: 'https://blog.fusion.ng',
    external: true,
  },
  {
    id: 'explore',
    name: 'Explore',
    path: '/explore/posts',
    external: false,
  },
  {
    id: 'support',
    name: 'Support',
    path: '#',
    external: false,
  },
  {
    id: 'signin',
    name: 'Login',
    path: '/signin',
    external: false,
  },
  {
    id: 'signup',
    name: 'Sign Up',
    path: '/signup',
    external: false,
  },
];
