import { createHash, createCipheriv, createDecipheriv } from 'crypto';
export const encryptPin = (text: string) => {
  const cipher = createCipheriv('des-ede', 'zAL7X5AVRm8l4Ifs', '');
  let encrypted = cipher.update(text, 'utf8', 'base64');
  encrypted += cipher.final('base64');
  return encrypted;
};

export const decryptPin = (encryptedText: string) => {
  const decipher = createDecipheriv('des-ede', 'zAL7X5AVRm8l4Ifs', '');
  let decrypted = decipher.update(encryptedText, 'base64', 'utf8');
  decrypted += decipher.final('utf8');
  return decrypted;
};

export const encryptPassword = (password: string) => {
  return createHash('sha1').update(password).digest('hex').toString();
};
