import { parsePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { z } from 'zod';

export const passwordValidations = [
  {
    label: 'Minimum of 8 characters',
    test: (pw: string) => pw.length >= 8,
  },
  {
    label: 'At least 1 uppercase letter',
    test: (pw: string) => /[A-Z]/.test(pw),
  },
  {
    label: 'At least 1 lowercase letter',
    test: (pw: string) => /[a-z]/.test(pw),
  },
  {
    label: 'At least 1 number',
    test: (pw: string) => /\d/.test(pw),
  },
  {
    label: 'At least 1 special character',
    test: (pw: string) => /[^A-Za-z0-9]/.test(pw),
  },
];

export const completeSignupValidationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
  gender: yup.string().required('This field is required'),
  dateOfBirth: yup.string().required('Date of birth is required!'),
  code: yup.string(),
});

export const signupValidationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  emailAddress: yup.string().email('Invalid email address').required('Email is required'),
  phoneNumber: yup
    .string()
    .required('Phone field is required.')
    .test('phoneNumber', 'Phone number not valid', (value) => {
      if (!value) return true;
      const phone = parsePhoneNumber(`${value}`);
      return phone?.isValid();
    }),
  acceptTerms: yup.boolean(),
});

export const signInValidationSchema = yup.object({
  username: yup.string().email('Invalid email address').required('First name is required'),
  password: yup.string().required('Password is required'),
});

export const bioDataCredentialsValidationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Minimum of 8 characters')
    .matches(/[A-Z]/, 'At least 1 uppercase letter')
    .matches(/[a-z]/, 'At least 1 lowercase letter')
    .matches(/\d/, 'At least 1 number')
    .matches(/[^A-Za-z0-9]/, 'At least 1 special character')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password') as any, null], 'Passwords must match')
    .required('Confirm password is required'),
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
  referrerId: yup.string().optional(),
});

export const bioDataValidationSchema = yup.object({
  gender: yup.string().required('Gender is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
  referrerId: yup.string().optional(),
});

export const usernameValidationSchema = yup.object({
  username: yup
    .string()
    .required('Username is required')
    .matches(/^.*[a-zA-Z]{3,}.*$/, 'Username not valid'),
});
export const emailValidationSchema = yup.object({
  email: yup.string().email('Email address is not valid').required('Email address is required'),
});
export const changePasswordValidationSchema = yup.object({
  password: yup.string().required('Current Password is required'),
  confirmPassword: yup
    .string()
    .min(8, 'Minimum of 8 characters')
    .matches(/[A-Z]/, 'At least 1 uppercase letter')
    .matches(/[a-z]/, 'At least 1 lowercase letter')
    .matches(/\d/, 'At least 1 number')
    .matches(/[^A-Za-z0-9]/, 'At least 1 special character')
    .required('Password is required')
    .notOneOf(
      [yup.ref('currentPassword')],
      'New password must be different from the current password'
    ),
});

export const interestValidationSchema = yup.object({
  interests: yup.array().of(yup.string().required('Select at list one interest')),
});

export const communityValidationSchema = z.object({
  category: z.string({ required_error: 'Please select a category.' }),
  name: z.string({
    required_error: 'Name is required.',
  }),
  interest: z.string({
    required_error: 'Please select an interest.',
  }),
  type: z.string({
    required_error: 'Please the type of community.',
  }),
});
